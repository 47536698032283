import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { useStyles } from './style'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'
import { useState } from '@hookstate/core'
import CONF from '../../../../config'
import { tableStyles } from '../../../../styles/globalStyle/tableStyles'
import AdminEducationListFooter from './footer'
import { EducationAction, EducationState } from '../../../Education/state'
import { ROUTES, CONTENT_ADMIN_ROUTES } from '../../../../constant'
import { LanguageState } from '../../../Layout/Header/state'
import { GlobalTableCell, GlobalTableRow } from 'globals/style'

export default function AdminEducationList() {
  const classes = useStyles()
  const tableStylesClass = tableStyles()
  const { t, i18n } = useTranslation()

  const listState = useState(EducationState.list)
  const categoryState = useState(EducationState.category)
  const listPageNoState = useState(EducationState.listPageNo)
  const languageState = useState(LanguageState.language)

  useEffect(() => {
    EducationAction.loadEducation(
      listPageNoState.get(),
      CONF.fetchCount,
      '',
      categoryState.get(),
      languageState.get()
    )()
  }, [listPageNoState.get(), categoryState.get(), languageState.get()])

  return (
    <Paper className={classes.paper} elevation={0}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <Typography className={tableStylesClass.header} variant="body2" gutterBottom>
                {/*{t('Title')}*/}
                {t(`${categoryState.get() !== '' ? categoryState.get() : 'All'}`)}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listState.data.get().length > 0 ? (
            listState.data.get().map((row: any, taskIndex) => (
              <GlobalTableRow key={row.title}>
                <GlobalTableCell
                  component="th"
                  scope="row"
                  onClick={() => {
                    EducationState.selectEducationToEdit.set(row.slug)
                    EducationAction.loadEducationDetail(row.slug)().then(() => navigate(`${ROUTES.EDUCATION_EDIT}`))
                    // navigate(row.slug)
                  }}
                >
                  <Typography className={classes.titleTypo} variant="body2" gutterBottom>
                    {row.title}
                  </Typography>
                </GlobalTableCell>
              </GlobalTableRow>
            ))
          ) : (
            <GlobalTableRow>
              <GlobalTableCell align="right">No record</GlobalTableCell>
            </GlobalTableRow>
          )}
        </TableBody>
        <AdminEducationListFooter />
      </Table>
    </Paper>
  )
}
