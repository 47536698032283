import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles'
import TableRow from '@material-ui/core/TableRow'
import MuiTableCell from '@material-ui/core/TableCell'

export const useGlobalStyles = makeStyles((theme) => ({
  toolbarSecondary: {
    justifyContent: 'space-between',
    overflowX: 'auto',
    // height: 40,
  },
}))

export const GlobalTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: 'rgb(248,248,248)',
      },
    },
  })
)(TableRow)

export const GlobalTableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(MuiTableCell)
