import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      // minWidth: 650,
      // tableLayout: 'fixed',
    },
    pagination: {},
    customPagination: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
    tableFooter: {
      // left: 0,
      // bottom: 0, // <-- KEY
      // zIndex: 2,
      // position: 'sticky'
    },
    titleTypo: {
      marginLeft: theme.spacing(2),
      cursor: 'pointer',
    },
  })
)
