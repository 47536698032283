import { createState } from '@hookstate/core'
import { getAxios, postAxios, putAxios } from '../../utils/parse'
import { API, REST_URL } from '../../constant'
import { IEducationDetail } from './interface'

export const EducationState = {
  category: createState(''),
  list: createState({ data: [], page: 0, totalPage: 0, count: 0, errorMessage: '' }),
  listPageNo: createState(0),
  educationDetail: createState<IEducationDetail>({}),
  selectEducationToEdit: createState<string>(''),
}

export const EducationAction = {
  loadEducation(pageNo: number, fetchCount: number, title: string = '', category: string, language: string = 'vn') {
    return async () => {
      try {
        EducationState.list.set({ data: [], page: 0, totalPage: 0, count: 0, errorMessage: '' })

        const params = { start: pageNo * fetchCount, limit: fetchCount, searchTitle: title, category, language }
        const posts = await getAxios(REST_URL.EDUCATION.getList, params)
        const postsCount = await getAxios(REST_URL.EDUCATION.count, params)

        EducationState.list.set({
          data: posts,
          page: pageNo,
          totalPage: Math.ceil(postsCount.count / fetchCount),
          count: postsCount.count,
          errorMessage: '',
        })
      } catch (err) {
        EducationState.list.set({ data: [], page: 0, totalPage: 0, count: 0, errorMessage: '' })
      }
    }
  },

  loadEducationDetail(slug: string) {
    return async () => {
      try {
        EducationState.educationDetail.set({})
        const response = await getAxios(REST_URL.EDUCATION.findOne(slug), {})
        EducationState.educationDetail.set(response)
      } catch (err) {
        EducationState.educationDetail.set({})
      }
    }
  },
  addView(slug: string) {
    return async () => {
      await postAxios(REST_URL.EDUCATION.addView, { slug })
    }
  },
  editEducation(slug, title, category, content) {
    return async () => {
      const newSlug = await putAxios(REST_URL.EDUCATION.update(slug), {
        title,
        category,
        content,
      })
      return newSlug
    }
  },
  createEducation(title, category, content, language) {
    return async () => {
      await postAxios(API.EDUCATION.create, {
        title,
        category,
        content,
        language,
      })
    }
  },
}
