import React from 'react'
import AdminLayout from '../../components/ContentAdmin'
import AdminEducationTab from '../../components/ContentAdmin/EducationTab'

export default () => {
  return (
    <AdminLayout>
      <AdminEducationTab />
    </AdminLayout>
  )
}
