import { createStyles, makeStyles } from '@material-ui/core/styles'

export const tableStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: '100%',
      // marginBottom: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
    table: {
      // minWidth: 650,
      // tableLayout: 'fixed',
    },
    header: {
      // color: '#0F69FF',
      fontWeight: 700,
      // fontSize: '11px',
      // display: 'block',
      // fontFamily: ['SourceSansPro', 'sans-serif'].join(','),
      // // textAlign: 'left',
      // whiteSpace: 'nowrap',
      //
      // fontStretch: '100%',
      // textIndent: '0px',
      // lineHeight: '14.3px',
    },
    pagination: {
      // '& > *': {
      //   paddingLeft: theme.spacing(40),
      // },
    },
    stockCode: {
      // layout
      display: 'block',
      maxWidth: '100%',
      overflowX: 'hidden',
      overflowY: 'hidden',

      // text
      color: 'rgb(15, 105, 255)',
      fontWeight: 700,
      fontSize: '14px',
      fontFamily: ['SourceSansPro', 'sans-serif'].join(','),
      fontStretch: '100%',
      textIndent: '0px',
      lineHeight: '18.2px',
      whiteSpace: 'nowrap',
      textDecorationLine: 'none',
      textDecorationStyle: 'solid',
      textDecorationThickness: 'auto',
      textOverflow: 'ellipsis',
      textSizeAdjust: '100%',

      // other
      textRendering: 'optimizeLegibility',
      // -webkit-font-smoothing antialiased
      hyphens: 'none',
    },
    numberStyle: {
      // color: 'rgb(0, 135, 60)',
      fontWeight: 700,
      fontSize: '14px',
      // display: 'block',
      fontFamily: ['SourceSansPro', 'sans-serif'].join(','),
      fontStretch: '100%',
      textIndent: '0px',
      lineHeight: '18.2px',
    },
  })
)
