import { Link, navigate } from 'gatsby'
import Button from '@material-ui/core/Button'
import React from 'react'
import { ROUTES, CONTENT_ADMIN_ROUTES } from '../../../constant'
import AdminEducationList from './EducationList'
import { useStyles } from './style'

export default function AdminEducationTab() {
  const classes = useStyles()

  return (
    <>
      <Button
        // className={classes.createPostButton}
        variant="contained"
        onClick={() => navigate(`${ROUTES.CONTENT_ADMIN}${CONTENT_ADMIN_ROUTES.EDUCATION_CREATE}`)}
      >
        Create Post
      </Button>

      <AdminEducationList />
      {/*<Button*/}
      {/*  // className={classes.editButton}*/}
      {/*  variant="contained"*/}
      {/*  onClick={() => navigate(`${ROUTES.CONTENT_ADMIN}${SUB_ROUTES.EDUCATION_EDIT}`)}*/}
      {/*>*/}
      {/*  Edit*/}
      {/*</Button>*/}
    </>
  )
}
